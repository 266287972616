import { CtaEvent } from '@/libs/v2/components/createRoomOptionCTA/types';
import { useCallback } from 'react';
import type { UI } from '@/v2/types';
import useSearchQuery from '@/libs/v2/hooks/useSearchQuery';
import { useScrollIntoView } from '@/libs/v2/components/InView';
import { useSectionControls } from '@/v2/components/pages/PackageView/contexts/SectionControlsContext';
import { useSearchPanelControls } from '@/v2/components/pages/PackageView/contexts/SearchPanelControlsContext';
import scrollTo from '@/libs/v2/utils/scrollIntoView';
import useBreakpoints, { isScreen } from '@/libs/v2/hooks/useBreakpoints';
import { roomCtaDataLayerEvent } from '@/v2/components/pages/PackageView/utils/dataLayer/dataLayer';

export type OnCtaClickOpts = {
  roomOption: UI.RoomOption;
  roomIndex: number;
  ctaText: string;
};

const useCtaOnClick = () => {
  const scrollIntoView = useScrollIntoView();
  const { packageOptions } = useSectionControls();
  const { openCalendar, openTravellers, openMobileSearchPanelModal } =
    useSearchPanelControls();

  // Screen size info
  const breakpoints = useBreakpoints();
  const isDesktopScreen = isScreen(breakpoints)('md', 'lg');

  const { setSearchQuery } = useSearchQuery();
  return useCallback(
    (event: CtaEvent, { roomOption, roomIndex, ctaText }: OnCtaClickOpts) => {
      if (ctaText && roomIndex) {
        roomCtaDataLayerEvent({ action: ctaText, roomIndex });
      }
      switch (event) {
        case 'SELECT_ROOM':
          setSearchQuery({ room: roomOption?.roomType.id });
          return;
        case 'CHANGE_DATES':
          if (!isDesktopScreen) {
            openMobileSearchPanelModal();
          }
          scrollTo('#date-range', { offset: -70, behavior: 'smooth' });
          openCalendar(roomOption?.roomType.id);
          return;
        case 'CHANGE_TRAVELLERS':
          if (!isDesktopScreen) {
            // Don't open travellers for mobile
            return openMobileSearchPanelModal();
          }
          scrollTo('#travellers', { offset: -100, behavior: 'smooth' });
          openTravellers();
          return;
        case 'CHANGE_PACKAGE_OPTION':
          packageOptions.open();
          scrollIntoView('package', { behavior: 'smooth' });
          return;
        default:
      }
    },
    [
      isDesktopScreen,
      openCalendar,
      openMobileSearchPanelModal,
      openTravellers,
      packageOptions,
      scrollIntoView,
      setSearchQuery,
    ],
  );
};

export default useCtaOnClick;
