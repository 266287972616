import { PAY_WITH_OPTIONS } from '@/constants';

export const convertBooleanToPayWithButtonGroupProps = (
  usePoints,
  usePointsPlusPay,
) => {
  if (!usePoints) return PAY_WITH_OPTIONS.cash;

  if (usePoints && usePointsPlusPay) return PAY_WITH_OPTIONS.pointsPlusPay;

  return PAY_WITH_OPTIONS.points;
};

export const convertPayWithOptionsToBoolean = (payWithOption) => {
  switch (payWithOption) {
    case PAY_WITH_OPTIONS.cash:
      return { usePoints: false, usePointsPlusPay: false };

    case PAY_WITH_OPTIONS.points:
      return { usePoints: true, usePointsPlusPay: false };

    case PAY_WITH_OPTIONS.pointsPlusPay:
      return { usePoints: true, usePointsPlusPay: true };
  }
};
