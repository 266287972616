import { useEffect, useMemo, useState } from 'react';
import useSearchQuery from '../useSearchQuery';
import {
  cheapestByOfferPrice,
  RoomWithMatchingOffer,
} from '../../utils/roomOptions';

type BaseRoomOption = RoomWithMatchingOffer & {
  roomType: {
    id: string;
  };
};

export type UseAutoSelectedRoomOptionProps<T extends BaseRoomOption> = {
  isLoading: boolean | undefined;
  isPreSearch: boolean | undefined;
  roomOptions: T[] | undefined | null;
  calendarRoomId?: string;
  setCalendarRoomId?: (roomId: string | undefined) => void;
};

const useAutoSelectedRoomOption = <T extends BaseRoomOption>({
  isLoading,
  isPreSearch,
  roomOptions,
  calendarRoomId,
  setCalendarRoomId,
}: UseAutoSelectedRoomOptionProps<T>) => {
  const [preSearchToPostSearch, setPreSearchToPostSearch] = useState(true);
  const { searchQuery, setSearchQuery } = useSearchQuery();
  const { room } = searchQuery || {};

  const autoSelectedRoom = useMemo(() => {
    if (room === calendarRoomId) {
      setCalendarRoomId && setCalendarRoomId(undefined);
    }

    let currentSelection = roomOptions?.find(
      (option) => option.roomType.id === room,
    );

    // Don't update selection while loading or before results are back
    if (isLoading || isPreSearch) {
      return currentSelection;
    }

    const cheapestOption = roomOptions
      ? cheapestByOfferPrice(roomOptions)
      : undefined;

    // If no selection but there are rooms available OR current selection is
    // invalid => select cheapest available option or clear selection
    const shouldUseCheapestOption =
      (!currentSelection && cheapestOption) ||
      (currentSelection && !currentSelection.matchingOffer);

    if (preSearchToPostSearch) {
      setPreSearchToPostSearch((prev) => !prev);
      return calendarRoomId
        ? roomOptions?.find((option) => option.roomType.id === calendarRoomId)
        : cheapestOption;
    }

    if (
      room === undefined &&
      calendarRoomId === undefined &&
      shouldUseCheapestOption
    ) {
      return cheapestOption;
    }

    return currentSelection;
  }, [isLoading, isPreSearch, room, calendarRoomId, roomOptions]);

  useEffect(() => {
    const validSelectId = autoSelectedRoom?.roomType?.id;

    if (
      (room && validSelectId !== room) || // if it has room and user change to the other one, we return to the selected one
      (!room && !calendarRoomId && validSelectId) // if it is no room selected, we return the cheapest one
    ) {
      setSearchQuery({ room: validSelectId });
    } else if (calendarRoomId && validSelectId) {
      setSearchQuery({ room: calendarRoomId });
    }
  }, [autoSelectedRoom?.roomType?.id, calendarRoomId, room, setSearchQuery]);

  return autoSelectedRoom;
};

export default useAutoSelectedRoomOption;
