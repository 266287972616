import qs from '../../utils/qs';
import Router from 'next/router';
import { pickAll, map } from 'ramda';
import { SearchQueryParam } from './types';

const KEYS: SearchQueryParam[] = [
  'originCode',
  'departureDate',
  'returnDate',
  'adults',
  'children',
  'infants',
  'packageOption',
  'room',
  'noCampaign',
  'view',
  'offerId',
];

const parseNum = (num: string | undefined) =>
  num != undefined ? Number(num) : num;
const parseArray = (str: string | string[] | undefined) =>
  Array.isArray(str) ? str[0] : str;

export const getSearchQuery = (
  queryParams: Record<string, string | string[] | undefined>,
) => {
  if (!queryParams) {
    return undefined;
  }
  const searchQueryParams = pickAll(KEYS, queryParams);
  const squashedArrayParams = map<
    Record<SearchQueryParam, string | string[] | undefined>,
    Record<SearchQueryParam, string | undefined>
  >(parseArray, searchQueryParams);

  return {
    ...squashedArrayParams,
    adults: parseNum(squashedArrayParams.adults),
    children: parseNum(squashedArrayParams.children),
    infants: parseNum(squashedArrayParams.infants),
  };
};

export const updateRouteQuery = (
  newData: Record<string, any>,
  reset = false,
) => {
  // https://www.qantas.com is just used in order
  // to be able to use the URL constructor, it is not actually used
  // when using Router.replace
  const url = new URL(Router.asPath, 'https://www.qantas.com');
  const { hash, pathname, search } = url;

  return Router.replace(
    {
      pathname,
      search: qs.stringify({
        ...(!reset && { ...qs.parse(search) }),
        ...newData,
      }),
      hash,
    },
    undefined,
    { shallow: true },
  );
};
