import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSessionStorage } from 'react-use';
import useSearchQuery from '../useSearchQuery';
import { SessionStorage } from '../../constants/storage';

const useFlyingFrom = (originCodes: string[]) => {
  const { isReady: isRouterReady } = useRouter();
  const { searchQuery, setSearchQuery } = useSearchQuery();
  const [searchParams, setSearchParams]: [
    { originCode: string },
    (value: { originCode: string }) => void,
  ] = useSessionStorage(SessionStorage.SEARCH_PARAMS_STORAGE_KEY);
  const queryOriginCode = searchQuery?.originCode || '';
  const storageOriginCode = searchParams?.originCode || '';

  const isQueryOriginValid = !!originCodes?.includes(queryOriginCode);
  const isStorageOriginValid = !!originCodes?.includes(storageOriginCode);

  // Initial setup, only once on mount: sync originCode based on local storage or the URL
  useEffect(() => {
    // get the origin code from local storage and set it to query params or the vice versa
    if (isRouterReady) {
      if (isStorageOriginValid && !isQueryOriginValid) {
        setSearchQuery({ originCode: storageOriginCode });
      }

      if (isQueryOriginValid && !isStorageOriginValid) {
        setSearchParams({ originCode: queryOriginCode });
      }
    }
  }, []);

  useEffect(() => {
    if (isRouterReady) {
      if (searchQuery?.originCode === '') {
        // when 'All' location is selected, it will set originCode to null.
        // At this point we want to reset the searchQuery as well as the local storage origin code
        setSearchQuery({ originCode: undefined }, true);
        setSearchParams({ originCode: '' });
      } else {
        // if there's a valid storage origin and no valid query origin, update query
        if (isStorageOriginValid && !isQueryOriginValid) {
          setSearchQuery({ originCode: storageOriginCode });
        }

        // if there's a valid query origin and no valid storage origin, update storage
        if (isQueryOriginValid && !isStorageOriginValid) {
          setSearchParams({ originCode: queryOriginCode });
        }
      }
    }
  }, [
    isQueryOriginValid,
    isRouterReady,
    isStorageOriginValid,
    queryOriginCode,
    searchQuery,
    setSearchParams,
    setSearchQuery,
    storageOriginCode,
  ]);

  const setOriginCode = (originCode: string) => {
    if (!!originCodes?.includes(originCode)) {
      setSearchQuery({ originCode });
      setSearchParams({ originCode });
    }
  };

  // When using sessionStorage to restore the searchQuery, the originCode that we return from
  // this hook will be undefined for the first few renders.
  // In this state, components using this hook won't know if there truly is no originCode set
  // OR if the originCode in sessionStorage is currently being restored to searchQuery.
  // isReady allows us to confirm the validity of the originCode.
  const isReady = useMemo(() => {
    if (isStorageOriginValid && !isQueryOriginValid) {
      return searchQuery?.originCode === storageOriginCode;
    }

    return isRouterReady;
  }, [
    isQueryOriginValid,
    isStorageOriginValid,
    storageOriginCode,
    searchQuery?.originCode,
    isRouterReady,
  ]);

  return {
    //Origin code in query takes precedence, as we have case where we keep invalid code in session storage
    //When user selects 'SYD' as origin, then goes to Sydney destination page, we want to clear the Flying From and query
    //But we keep 'SYD' in session storage, so when user goes back to another destination page (for example Cairns) we can pre-select 'SYD' as origin
    originCode: isQueryOriginValid ? queryOriginCode : undefined,
    setOriginCode,
    isReady,
  };
};

export default useFlyingFrom;
