import PropTypes from 'prop-types';
import React, { useMemo, useCallback, useEffect } from 'react';
import Case from 'case';
import Router from 'next/router';
import userInteractionEvent from '../../../../../libs/shared/utils/userInteractionEvent';
import getPageNameFromPath from '../../../../../libs/shared/utils/getPageNameFromPath';
import dataLayer from '../../../../../libs/shared/utils/dataLayer';
import {
  PayWithButtonGroup as RooPayWithButtonGroup,
  Box,
  Text,
} from '@qga/roo-ui/components';
import { PAY_WITH_POINTS_MIN } from '@/constants';
import {
  convertBooleanToPayWithButtonGroupProps,
  convertPayWithOptionsToBoolean,
} from './utils/parsePayWithOptions';
import { useCustomerSelection } from '@/components/CustomerSelectionProvider';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { useDataLayerContext } from '@/libs/v2/contexts/DataLayerContext';
import { pointsToggleEvent } from '@/components/PayWithButtonGroup/utils/datalayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

const PayGroupContainer = styled(Box)`
  && input:disabled + label {
    background: ${themeGet('colors.white')};
  }
`;

const PayWithButtonGroup = ({
  pointsPlusPayEnabled,
  toolTipDirection,
  disabled,
  ...props
}) => {
  const { usePoints, setUsePoints, usePointsPlusPay, setUsePointsPlusPay } =
    useCustomerSelection();
  const shouldUseNewEvents = useGA4Events();

  const { pageName } = useDataLayerContext() || {};

  const payWithValue = useMemo(() => {
    return (
      convertBooleanToPayWithButtonGroupProps(usePoints, usePointsPlusPay) ||
      'cash'
    );
  }, [usePoints, usePointsPlusPay]);

  const onChange = useCallback(
    (payWithOption) => {
      const { usePoints: withPoints, usePointsPlusPay: withPointsPlusPay } =
        convertPayWithOptionsToBoolean(payWithOption);

      shouldUseNewEvents && pointsToggleEvent(payWithOption);
      dataLayer.push(
        userInteractionEvent(
          pageName ?? getPageNameFromPath(Router.asPath),
          `${Case.capital(payWithOption)} selected`,
          'Points And Cash Toggle',
        ),
      );

      setUsePoints(withPoints);
      setUsePointsPlusPay(withPointsPlusPay);
    },
    [pageName, setUsePoints, setUsePointsPlusPay, shouldUseNewEvents],
  );

  useEffect(() => {
    if (!pointsPlusPayEnabled && usePointsPlusPay) {
      setUsePoints(false);
      setUsePointsPlusPay(false);
    }
  }, [
    pointsPlusPayEnabled,
    setUsePoints,
    setUsePointsPlusPay,
    usePointsPlusPay,
  ]);

  return (
    <PayGroupContainer
      mb={[4, null, 0]}
      position="relative"
      width={['100%', null, 'auto']}
      {...props}
    >
      <RooPayWithButtonGroup
        disabled={disabled}
        value={payWithValue}
        onChange={(payWithOption) => onChange(payWithOption)}
        pointsPlusPayEnabled={pointsPlusPayEnabled}
      />
      <Box position="absolute" right="0">
        <Text color="greys.steel" fontSize="2xs">
          Minimum {PAY_WITH_POINTS_MIN} PTS required
        </Text>
      </Box>
    </PayGroupContainer>
  );
};

PayWithButtonGroup.defaultProps = {
  PayWithButtonGroup: false,
  toolTipDirection: 'top',
  disabled: false,
};

PayWithButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  pointsPlusPayEnabled: PropTypes.bool,
  toolTipDirection: PropTypes.oneOf(['top', 'right']),
};

export default PayWithButtonGroup;
